import axios from 'axios';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { CDP_DASHBOARD_URLS } from '@config/api-config';
import { APP_LABELS, CDP, STORAGE_KEYS } from '@config/app-config';
import {
  getCdpAssociateDashboard,
  saveCdpAssociation,
  updateViewStatus
} from '@redux/chronic-disease-program/associate-dashboard/actions';
import {
  detailPageTypeAction,
  saveCdpFetchIdAction,
  saveFetchTypeAction
} from '@redux/chronic-disease-program/upload-data/actions';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { getAssociationByNumber } from '@redux/chronic-disease-program/admin-dashboard/actions';

import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';

import useSessionStorage from '@hooks/useSessionStorage';
import FileMimeType from '@utilities/FileMimeTypes';
import SecureStorage from '@utilities/SecureStorage';
import userService from '@utilities/user-service';

import BPSDARK from '@static/images/Blood-Pressure-Self-Monitoring-Colored.svg';
import BPSLIGHT from '@static/images/Blood-Pressure-Self-Monitoring-white.svg';
import DPPDARK from '@static/images/YMCA-Diabetes-Prevention-Program-Colored.svg';
import DPPLIGHT from '@static/images/YMCA-Diabetes-Prevention-Program-white.svg';
import EFDARK from '@static/images/Enhance-Fitness-Colored.svg';
import EFLIGHT from '@static/images/Enhance-Fitness-white.svg';
import HWDARK from '@static/images/Healthy-Weight-your-Child-Colored.svg';
import HWLIGHT from '@static/images/Healthy-Weight-your-Child-White.svg';
import LSDARK from '@static/images/LS-dark-(2).svg';
import LSLIGHT from '@static/images/LS-light-(2).svg';
import YWPDARK from '@static/images/YWP-dark.svg';
import YWPLIGHT from '@static/images/YWP-light.svg';
import Breadcrumb from '@static/images/breadcrumb-icon.svg';
import exclamation from '@static/images/exclamation.png';

import ASSOCIATEDASHBOARDCOLUMNS from './associate-dashboard-columns';
import Table from './table';
import DueDate from '../due-date';
import Toaster from '@modules/shared/components/reusable-components/toaster';

const CDPAssociatedashboard = props => {
  const {
    handelGetCdpAssociateDashboard,
    handelDetailPageTypeAction,
    handelSaveCdpFetchIdAction,
    handelSaveFetchTypeAction,
    handelGetAssociationByNumber,
    handelSaveCdpAssociation,
    handleUpdateViewStatus,
    location
  } = props;

  const initalTableState = {
    autoResetSortBy: true,
    autoResetPage: true,
    autoResetFilters: true,
    autoResetGlobalFilter: true
  }

  const authToken = JSON.parse(sessionStorage.getItem('token'));

  const [loader, setLoader] = useState(false);
  const [totalRecordsDisplay, setTotalRecordsDisplay] = useState();
  const [kpiData, setKpiData] = useState();
  const [selectedKPI, setSelectedKPI] = useState('');
  const [dueDateData, setDueDateData] = useState([]);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [associationName, setAssociationName] = useState('');
  const [associationNumber, setAssociationNumber] = useState('');
  const [initialProgramId, setInitialProgramId] = useState(0);
  const [tableState, setTableState] = useState(initalTableState);
  const [toastStatus, setToastStatus] = useState({ status: '', message: '' });
  const [hiddenRecordCountUi, setHiddenRecordCountUi] = useState(0);
  const [showHideButtonText, setShowHideButtonText] = useState("Show Hidden Records");
  const [isHiddenRecordShowing, setIsHiddenRecordShowing] = useState(false);


  const [association, setAssociation] = useSessionStorage(
    CDP.STORAGE_KEYS.ASSOCIATION_DATA,
    {}
  );
  const activeKPICard = JSON.parse(sessionStorage.getItem('selectedKPI')) || {
    programName: CDP.PROGRAM_NAMES.BPSM
  };

  useEffect(() => {
    if (kpiData) {
      const fileteredDueDateData = kpiData.filter(
        card => card.programName === selectedKPI
      );
      setDueDateData(fileteredDueDateData);
      if (fileteredDueDateData && fileteredDueDateData?.length > 0) {
        setInitialProgramId(fileteredDueDateData[0].programId);
      }
    }
  }, [selectedKPI, kpiData]);

  const getAssociationName = async (kpiprogramID, showHidden = false) => {
    setLoader(true);
    let request;
    if (location?.state?.associationNumber) {
      request = location.state.associationNumber;
    } else if (association?.associationNumber) {
      request = association.associationNumber;
    } else if (sessionStorage.getItem(STORAGE_KEYS.YUSA_SELECTED_ASSOCIATE)) {
      const data = JSON.parse(
        sessionStorage.getItem(STORAGE_KEYS.YUSA_SELECTED_ASSOCIATE)
      );
      request = data.associationNumber;
    } else {
      [request] = userService.getAssociation();
    }
    const dashboardResponse = await handelGetCdpAssociateDashboard(
      request,
      kpiprogramID || '',
      showHidden
    );
    const response = await handelGetAssociationByNumber(request);
    if (dashboardResponse.status === 200) {
      setTotalRecordsDisplay(
        dashboardResponse?.data?.data?.changeLogdetails?.details
      );
      setKpiData(dashboardResponse?.data?.data?.summeray);
      setHiddenRecordCountUi(dashboardResponse?.data?.data?.hiddenRecordCount);
    } else {
      setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
      setErrorPopup(true);
    }
    setAssociation(response?.data);
    setAssociationName(response?.data.yName);
    setAssociationNumber(request);
    handelSaveCdpAssociation(response?.data);
    setLoader(false);
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    setSelectedKPI(activeKPICard?.programName || CDP.PROGRAM_NAMES.BPSM);
    await getAssociationName(activeKPICard?.programId || '');
  }, []);

  const getFileName = (row, fileName, type) => {
    let setFileName;
    let contentType = type;
    if (!contentType) {
      // eslint-disable-next-line prefer-destructuring
      contentType = fileName.split('.')[1];
    }
    if (
      row.original.dataSystemName === CDP.DATA_SYSTEM_NAMES.SOLERA ||
      row.original.dataSystemName === CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC
    ) {
      setFileName =
        row.original.dataSystemName === CDP.DATA_SYSTEM_NAMES.SOLERA
          ? `${CDP.DATA_SYSTEM_NAMES.SOLERA}-${row.original.createdDate}.${contentType}`
          : `${CDP.DATA_SYSTEM_NAMES.THIRDPARTY_GENERIC}-${row.original.createdDate}.${contentType}`;
    } else if (row.original.dataSystemName === CDP.DATA_SYSTEM_NAMES.HABITNU) {
      setFileName = `${CDP.DATA_SYSTEM_NAMES.HABITNU}-${row.original.createdDate}.${contentType}`;
    } else if (row.original.dataSystemName === CDP.DATA_SYSTEM_NAMES.OCHI) {
      setFileName = `${CDP.DATA_SYSTEM_NAMES.OCHI}-${row.original.createdDate}.${contentType}`;
    } else if (row.original.dataSystemName === CDP.DATA_SYSTEM_NAMES.DOL) {
      setFileName = `${CDP.DATA_SYSTEM_NAMES.DOL}-${row.original.createdDate}.${contentType}`;
    } else if (row.original.dataSystemName === CDP.DATA_SYSTEM_NAMES.WELLD) {
      setFileName = `${CDP.DATA_SYSTEM_NAMES.WELLD}-${row.original.createdDate}.${contentType}`;
    } else if (row.original.dataSystemName === CDP.DATA_SYSTEM_NAMES.ODES) {
      setFileName = `${CDP.DATA_SYSTEM_NAMES.ODES}-${row.original.createdDate}.${contentType}`;
    }
    return setFileName;
  };

  const downloadFile = (fileName, row) => {
    setLoader(true);
    axios
      .get(CDP_DASHBOARD_URLS.DOWNLOAD_FILE(fileName), {
        responseType: 'blob',
        headers: {
          'Content-Type': 'blob',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Origin': window.location.origin,
          'Ocp-Apim-Subscription-Key': '3c92bec8b5df41c99f31bd7fc2057dd3',
          Authorization: `Bearer ${get(authToken, 'token', '')}`
        }
      })
      .then(response => {
        const fileExtension = FileMimeType[response.headers['content-type']];
        const fileContentName = getFileName(row, fileName, fileExtension);
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileContentName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoader(false);
      });
  };

  const onLinkClick = data => {
    SecureStorage.setItem(STORAGE_KEYS.FETCH_ID, data.original.cdpDataFetchId);
    handelDetailPageTypeAction(CDP.DETAIL_PAGE_TYPE.VIEW);
    handelSaveCdpFetchIdAction(data.original.cdpDataFetchId);
    handelSaveFetchTypeAction(data.original.dataSystemName || '');
  };

  const getKpiClasses = programValue => {
    switch (programValue) {
      case CDP.PROGRAM_NAMES.DPP:
        return `${activeKPICard.programName === programValue
          ? 'ymca-diabetes-prevention-card-active'
          : 'ymca-diabetes-prevention-card'
          }`;
      case CDP.PROGRAM_NAMES.BPSM:
        return `${activeKPICard.programName === programValue
          ? 'ymca-bp-monitoring-card-active'
          : 'ymca-bp-monitoring-card'
          }`;
      case CDP.PROGRAM_NAMES.EF:
        return `${activeKPICard.programName === programValue
          ? 'ymca-fitness-card-active'
          : 'ymca-fitness-card'
          }`;
      case CDP.PROGRAM_NAMES.HWYC:
        return `${activeKPICard.programName === programValue
          ? 'ymca-child-health-card-active'
          : 'ymca-child-health-card'
          }`;
      case CDP.PROGRAM_NAMES.LS:
        return `${activeKPICard.programName === programValue
          ? 'ymca-strong-life-card-active'
          : 'ymca-strong-life-card'
          }`;
      case CDP.PROGRAM_NAMES.YWP:
        return `${activeKPICard.programName === programValue
          ? 'ymca-youth-workforce-card-active'
          : 'ymca-youth-workforce-card'
          }`;
      default:
        return 'ymca-diabetes-prevention-card';
    }
  };

  const getKpiDarkIcon = programValue => {
    switch (programValue) {
      case CDP.PROGRAM_NAMES.DPP:
        return DPPDARK;
      case CDP.PROGRAM_NAMES.BPSM:
        return BPSDARK;
      case CDP.PROGRAM_NAMES.EF:
        return EFDARK;
      case CDP.PROGRAM_NAMES.HWYC:
        return HWDARK;
      case CDP.PROGRAM_NAMES.LS:
        return LSDARK;
      case CDP.PROGRAM_NAMES.YWP:
        return YWPDARK;
      default:
        return DPPDARK;
    }
  };

  const getKpiLightIcon = programValue => {
    switch (programValue) {
      case CDP.PROGRAM_NAMES.DPP:
        return DPPLIGHT;
      case CDP.PROGRAM_NAMES.BPSM:
        return BPSLIGHT;
      case CDP.PROGRAM_NAMES.EF:
        return EFLIGHT;
      case CDP.PROGRAM_NAMES.HWYC:
        return HWLIGHT;
      case CDP.PROGRAM_NAMES.LS:
        return LSLIGHT;
      case CDP.PROGRAM_NAMES.YWP:
        return YWPLIGHT;
      default:
        return DPPLIGHT;
    }
  };

  const handleKpiCards = kpi => {
    sessionStorage.setItem(
      'selectedKPI',
      JSON.stringify({
        programName: kpi.programName,
        programId: kpi.programId
      })
    );
    setSelectedKPI(kpi.programName);
    getAssociationName(kpi.programId);
  };

  const displayLsOrEf = (kpi) => {
    switch (kpi.programDescription) {
      case CDP.PROGRAM_FULL_NAME.LS:
        return (<span> LIVESTRONG<sup>®</sup> at the YMCA</span>);
      case CDP.PROGRAM_FULL_NAME.EF:
        return (<span> Enhance<sup>®</sup>Fitness</span>);
      default:
        return (<>{kpi.programDescription}</>);
    }
  }

  const resetInitialTableState = () => {
    setTableState({
      autoResetFilters: true,
      autoResetGlobalFilter: true,
      autoResetPage: true,
      autoResetSortBy: true
    });
  }

  const onShowHideClick = async (row) => {
    const { cdpDataFetchId, isHidden } = row;
    const status = !isHidden;
    const response = await handleUpdateViewStatus(cdpDataFetchId, status);
    if (response.status === 200 && response.data.success) {
      const { hiddenRecordCount, recordDto } = response.data.result;
      setHiddenRecordCountUi(hiddenRecordCount);
      setTableState({
        autoResetFilters: false, autoResetGlobalFilter: false,
        autoResetPage: false, autoResetSortBy: false
      });
      totalRecordsDisplay.find(c => c.cdpDataFetchId === cdpDataFetchId).isHidden = recordDto.isHidden;
      setTotalRecordsDisplay(totalRecordsDisplay);
      if (status && !isHiddenRecordShowing) {
        const filteredRecords = totalRecordsDisplay?.filter(c => c.cdpDataFetchId !== cdpDataFetchId);
        setTotalRecordsDisplay(filteredRecords);
      }

      // if (status && !isHiddenRecordShowing) {
      //   const filteredRecords = totalRecordsDisplay?.filter(c => c.cdpDataFetchId !== cdpDataFetchId);
      //   setTotalRecordsDisplay(filteredRecords);
      // } else if (totalRecordsDisplay?.length > 0) {
      //   totalRecordsDisplay.find(c => c.cdpDataFetchId === cdpDataFetchId).isHidden = !status;
      //   setTotalRecordsDisplay(totalRecordsDisplay);
      // }
      resetInitialTableState();

      setToastStatus({
        status: APP_LABELS.STATUS_COMPLETED,
        message: status ? CDP.MESSAGES.updateStatusSuccess : CDP.MESSAGES.updateStatusShowSuccess
      });

    } else {
      setToastStatus({
        status: APP_LABELS.STATUS_FAILED,
        message: CDP.MESSAGES.updateStatusFailure
      });
    }
    setToastStatus({ status: '', message: {} });
  }

  const showHiddenRecords = async () => {
    let programId = activeKPICard?.programId || 0;
    if (!activeKPICard || !activeKPICard?.programId) {
      programId = initialProgramId;
    }
    if (programId) {
      await getAssociationName(programId, !isHiddenRecordShowing);
      setIsHiddenRecordShowing(!isHiddenRecordShowing);
    }
  }

  const getRowProps = (row, data) => ({
    className: data.row.original.isHidden ? "hidden-row" : ''
  });

  useEffect(() => {
    if (isHiddenRecordShowing) {
      setShowHideButtonText("Hide Hidden Records");
    } else {
      setShowHideButtonText("Show Hidden Records");
    }
  }, [isHiddenRecordShowing]);

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper ymca-cdp-assocate-dashboard">
        <main className="page-container">
          <div className="ymca-data-wrapper volunteer-dashboard-wrapper admin-cards">
            <div className="container">
              <section className="dashboard-header">
                <div className="row align-items-center">
                  <div className="col-lg-7 col-md-6 col-sm-12">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb ymca-breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/cdp/dashboard">Dashboard</Link>
                          <img
                            src={Breadcrumb}
                            className="breadcrumb-icon"
                            alt="double-right-arrow"
                          />
                        </li>
                        <li className="breadcrumb-item notranslate active">
                          {associationName}
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-lg-7 col-md-6 col-sm-12">
                    <div className="ymca-associate-info-wrapper">
                      <h2 className="ymca-sub-heading notranslate mb-2">
                        {associationName && <>{associationName} ({associationNumber})</>}
                      </h2>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12 text-lg-end text-md-end">
                    {kpiData !== null && totalRecordsDisplay !== null && (
                      <Link
                        className="btn ymca-primary-btn login-btn"
                        to="/cdp/upload/data"
                      >
                        + Upload New Data
                      </Link>
                    )}
                  </div>
                </div>
              </section>
              <hr className="custom-hr mt-0" />
              <section className="stats-data mb-4">
                <div className="row gx-3">
                  {kpiData?.map(kpi => (
                    <div
                      className="col-sm cdp-assocation-card-width"
                      key={kpi.programId}
                      aria-hidden="true"
                    >
                      <button
                        onClick={() => {
                          handleKpiCards(kpi);
                        }}
                        type="button"
                        className={`card stats-card-sm ${getKpiClasses(
                          kpi.programName
                        )}`}
                      >
                        <div className="d-flex align-items-center h-100">
                          <div className="card-icon">
                            <img
                              src={getKpiDarkIcon(kpi.programName)}
                              alt="DPPDARK"
                              className="dark-icon"
                            />
                            <img
                              src={getKpiLightIcon(kpi.programName)}
                              alt="DPPLIGHT"
                              className="light-icon"
                            />
                          </div>

                          <div className="card-title">
                            <p>
                              {displayLsOrEf(kpi)}
                            </p>
                          </div>
                        </div>
                      </button>
                    </div>
                  ))}
                </div>
                {kpiData === null && (
                  <div className="YMCCA-tostAlert overflow-hidden">
                    <div className="YMCCA-textList">
                      <img src={exclamation} alt="exclamation" />
                      <span>{CDP.MESSAGES.NotPartofAssociation}</span>
                    </div>
                  </div>
                )}

                {dueDateData?.length > 0 && (
                  <DueDate DPPDueDateData={dueDateData} />
                )}
              </section>
            </div>
          </div>
          {kpiData !== null && totalRecordsDisplay !== null && (
            <section className="dashboard-list">
              <div className="table-responsive custom-scrollbar">
                <Table
                  rows={totalRecordsDisplay}
                  headers={ASSOCIATEDASHBOARDCOLUMNS(downloadFile, onLinkClick, onShowHideClick)}
                  showCount
                  title={CDP.TITLES.DASHBOARD}
                  onShowRecordsClick={showHiddenRecords}
                  getRowProps={getRowProps}
                  autoResetSortBy={tableState.autoResetSortBy}
                  autoResetPage={tableState.autoResetPage}
                  autoResetFilters={tableState.autoResetFilters}
                  autoResetGlobalFilter={tableState.autoResetGlobalFilter}
                  hiddenRecordCount={hiddenRecordCountUi}
                  showHideButtonText={showHideButtonText}
                />
              </div>
            </section>
          )}
        </main>
        <Toaster status={toastStatus.status} message={toastStatus.message} closeTime={1000} />
      </div>
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
    </BlockUi>
  );
};

CDPAssociatedashboard.propTypes = {
  handelGetCdpAssociateDashboard: PropTypes.func.isRequired,
  handelDetailPageTypeAction: PropTypes.func.isRequired,
  handelSaveCdpFetchIdAction: PropTypes.func.isRequired,
  handelSaveFetchTypeAction: PropTypes.func.isRequired,
  handelGetAssociationByNumber: PropTypes.func.isRequired,
  handelSaveCdpAssociation: PropTypes.func.isRequired,
  handleUpdateViewStatus: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handelGetCdpAssociateDashboard: getCdpAssociateDashboard,
  handelDetailPageTypeAction: detailPageTypeAction,
  handelSaveCdpFetchIdAction: saveCdpFetchIdAction,
  handelSaveFetchTypeAction: saveFetchTypeAction,
  handelGetAssociationByNumber: getAssociationByNumber,
  handelSaveCdpAssociation: saveCdpAssociation,
  handleUpdateViewStatus: updateViewStatus
};

const mapStateToProps = state => ({
  loginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CDPAssociatedashboard));
